import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Imtahan1 from "../style/icons/Imtahan/im1.svg";
import Imtahan2 from "../style/icons/Imtahan/im2.svg";
import Imtahan3 from "../style/icons/Imtahan/im3.svg";
import Imtahan4 from "../style/icons/Imtahan/im4.svg";
import Imtahan5 from "../style/icons/Imtahan/im5.svg";
import Imtahan6 from "../style/icons/Imtahan/im6.svg";
import Imtahan7 from "../style/icons/Imtahan/im7.svg";
import Imtahan8 from "../style/icons/Imtahan/im8.svg";
import Imtahan9 from "../style/icons/Imtahan/im9.svg";

const ImtahanProsesiPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>İmtahan prosesinin aparılması</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='İmtahan prosesinin 
online təşkil edilməsi'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Kollokvium prosesinin
                                    təşkili
                                </h2>
                                <p className='tim-contHeaderPage'>Semestr ərzində kollokvium prosesinin təşkilinin aparılması moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Müxtəlif proqramlar üzrə təyin edilmiş kollokvium sırasının və onların məlumatlarının(tarix, korpus) qeyd edilməsi
                                    </li>
                                    <li>Kollokviuma hansı qrup və ya qrupların qatılacağının qeyd olunması
                                    </li>
                                    <li>Kollokvium cədvəlinin müəllim və tələbə portalına inteqrasiyası
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Imtahan1} alt="İmtahan prosesinin
                                online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Imtahan2} alt="İmtahan prosesinin
                                online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='İmtahan prosesinin 
online təşkil edilməsi'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Imtahan3} alt="İmtahan prosesinin
                                online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Imtahan4} alt="İmtahan prosesinin
                                online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Imtahan5} alt="İmtahan prosesinin
                                online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    İmtahan prosesinin
                                    təşkili
                                </h2>
                                <p className='tim-contHeaderPage'>Semestr ərzində imtahan prosesinin təşkilinin aparılması moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>İmtahana öncədən qeydiyyat(25%)
                                    </li>
                                    <li>Müxtəlif proqramlar üzrə təyin edilmiş imtahanların və onların məlumatlarının(tarix, korpus) qeyd edilməsi
                                    </li>
                                    <li>İmtahana hansı qrup və ya qrupların qatılacağının qeyd olunması
                                    </li>
                                    <li>İmtahan cədvəlinin müəllim və tələbə portalına inteqrasiyası
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='İmtahan prosesinin 
online təşkil edilməsi'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Biletlərin generasiyası
                                </h2>
                                <p className='tim-contHeaderPage'>Kollokvium və imtahan prosesi zamanı biletlərin generasiyası moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Seçilmiş kollokvium və qrup üzrə biletlərin generasiyası üçün kandidat sualların avtomatik təyinatı
                                    </li>
                                    <li>Qrupdakı tələbə sayına uyğun biletlərin generasiyası
                                    </li>
                                    <li>Sualların generasiyası zamanı bir biletdə təkrar sualın olmamasının kontrolu
                                    </li>
                                    <li>Sualların generasiyası zamanı davamedici fənlərin nəzərə alınması
                                    </li>
                                    <li>Hər bir tələbəyə bilet təyin olunması
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Imtahan6} alt="İmtahan prosesinin 
online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Imtahan7} alt="İmtahan prosesinin 
online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='İmtahan prosesinin 
online təşkil edilməsi'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Imtahan8} alt="İmtahan prosesinin 
online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Imtahan9} alt="İmtahan prosesinin 
online təşkil edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                Qiymətləndirmə
                                </h2>
                                <p className='tim-contHeaderPage'>Kollokvium və imtahan prosesi zamanı biletlərin qiymətləndirilməsi moduludur.  </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Tələbənin iştirak statusunun qeydiyyatı
                                    </li>
                                    <li>Bilet üzrə suallara baxış
                                    </li>
                                    <li>Seçilmiş bilet üzrə hər bir suallara qiymətlərin verilməsi
                                    </li>
                                    <li>Bilet üzrə ortalama qiymətin avtomatik hesablanması
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ImtahanProsesiPage