import React from 'react';
import {
    Container, Row, Col
} from "react-bootstrap";

const ModulHeader = () => {
    return (
        <div className='tim-modulsHeader' id='modullar'>
            <Container>
                <Row>
                    <Col className="text-center" lg={12}>
                        <h3 className="tim-subtitleUnder">Qurumunuzun hər bölməsi  <br />  üçün rəqəmsallaşma  </h3>
                        <p className='tim-underDetails'>DigitalEdu, qəbul prosesindən tədrisə hazırlığa, tədrisin aparılmasından imtahanlara, mühasibatlıqdan sənəd dövriyyəsinə, kitabxanadan portallara qədər ən müxtəlif modulların cəmləşdiyi vahid platformadır.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ModulHeader