import React from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLayerGroup,
    faPager,
    faClock,
    faShieldAlt
} from "@fortawesome/free-solid-svg-icons";

let about = [
    {
        title: "Kompleks",
        description: "Mərkəzləşdirilmiş tədris informasiya sistemi",
        iconBgColor: "tim-cardIconDiv purple",
        iconColor: "tim-cardIcon purple",
        icons: faLayerGroup,
    },
    {
        title: "Elektron",
        description: "Kağız işinin minimallaşdırılması",
        iconBgColor: "tim-cardIconDiv yellow",
        iconColor: "tim-cardIcon yellow",
        icons: faPager,
    },
    {
        title: "Sürətli",
        description: "Real vaxt zəminində hesabatlılıq",
        iconBgColor: "tim-cardIconDiv red",
        iconColor: "tim-cardIcon red",
        icons: faClock,
    },
    {
        title: "Təhlükəsiz",
        description: "Məlumatların təhlükəsizliyi",
        iconBgColor: "tim-cardIconDiv green",
        iconColor: "tim-cardIcon green",
        icons: faShieldAlt,
    },
]

const About = () => {
    return (
        <div className="tim-bgLight" id="niyeBiz">
            <Container>
                <Row>
                    <Col className="text-center" lg={12}>
                        <h2 className="tim-subtitle">Niyə DigitalEDU?</h2>
                    </Col>
                    {
                        about.map((a) => {
                            return (
                                <Col lg={3} md={6} sm={12} key={a.title}>
                                    <Card className='tim-card'>
                                        <Card.Body>
                                            <div className={a.iconBgColor}>
                                                <FontAwesomeIcon icon={a.icons} className={a.iconColor} />
                                            </div>
                                            <Card.Title>{a.title}</Card.Title>
                                            <Card.Text>
                                                {a.description}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default About