import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import MTPortal1 from "../style/icons/MuellimTebele/mtportal1.svg";
import MTPortal2 from "../style/icons/MuellimTebele/mtportal2.svg";
import MTPortal3 from "../style/icons/MuellimTebele/mtportal3.svg";
import MTPortal4 from "../style/icons/MuellimTebele/mtportal4.svg";
import MTPortal5 from "../style/icons/MuellimTebele/mtportal5.svg";
import MTPortal6 from "../style/icons/MuellimTebele/mtportal6.svg";
import MTPortal7 from "../style/icons/MuellimTebele/mtportal7.svg";
import MTPortal8 from "../style/icons/MuellimTebele/mtportal8.svg";
import MTPortal9 from "../style/icons/MuellimTebele/mtportal9.svg";
import MTPortal10 from "../style/icons/MuellimTebele/mtportal10.svg";
import MTPortal11 from "../style/icons/MuellimTebele/mtportal11.svg";
import MTPortal12 from "../style/icons/MuellimTebele/mtportal12.svg";
import MTPortal13 from "../style/icons/MuellimTebele/mtportal13.svg";
import MTPortal14 from "../style/icons/MuellimTebele/mtportal14.svg";
import MTPortal15 from "../style/icons/MuellimTebele/mtportal15.svg";
import MTPortal16 from "../style/icons/MuellimTebele/mtportal16.svg";
import MTPortal17 from "../style/icons/MuellimTebele/mtportal17.svg";
import MTPortal18 from "../style/icons/MuellimTebele/mtportal18.svg";
import MTPortal19 from "../style/icons/MuellimTebele/mtportal19.svg";
import MTPortal20 from "../style/icons/MuellimTebele/mtportal20.svg";
import MTPortal21 from "../style/icons/MuellimTebele/mtportal21.svg";

const MTPortalPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Müəllim və
                                tələbə portalı</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Müəllim portalı məlumatlar bölməsi
                                </h2>
                                <p className='tim-contHeaderPage'>Müəllim heyətinin özlərinə məxsus olan tədris prosesləri ilə bağlı məlumatlarına baxışın həyata keçirilməsi moduludur.  </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Şəxsi profil məlumatlarına baxış
                                    </li>
                                    <li>Həftəlik və ya cari gün üçün dərs cədvəllərinə baxış
                                    </li>
                                    <li>Aktiv semestr üzrə dərslərin siyahısı, onlar üzərindən davamiyyət və qiymətlərə baxış
                                    </li>
                                    <li>Universitet kitabxanası üzrə istifadəçi kartına baxış
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={MTPortal1} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal2} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal3} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal4} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal5} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={MTPortal6} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal7} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal8} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal9} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal10} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal11} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Müəllim portalı əməliyyatlar bölməsi
                                </h2>
                                <p className='tim-contHeaderPage'>Müəllim heyətinin özlərinə məxsus olan tədris prosesləri ilə bağlı məlumatlar üzərində əməliyyatların həyata keçirilməsi moduludur.  </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Dərslər üzrə müxtəlif formatda materialları tələbələrlə paylaşılması
                                    </li>
                                    <li>Jurnalların dərs tipinə əsasən davamiyyət və/və ya qiymətləndirmə seçiminin təyinatı
                                    </li>
                                    <li> Hər tələbəyə əvvəlcədən müəyyən olunmuş sayda sərbəst işlərin paylanılması və əvvəlcədən müəyyən olunmuş qiymət aralığı üzrə dəyərləndirilməsi
                                    </li>
                                    <li>Hər tələbəyə əvvəlcədən müəyyən olunmuş sayda(dərs saatlarına əsasən) laboratoriya işlərinin paylanılması və təhvil alınması
                                    </li>
                                    <li>Təqvim tematik planın bağlı olduğu ixtisas və dərsə əsasən, yalnız onlara uyğun mövzu paketinin avtomatik təyin olunması
                                    </li>
                                    <li> Kollokvium və imtahanların nəticələrinin qeydiyyatı
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tələbə portalı məlumatlar bölməsi
                                </h2>
                                <p className='tim-contHeaderPage'>Tələbə heyətinin özlərinə məxsus olan tədris prosesləri ilə bağlı məlumatlarına baxışın həyata keçirilməsi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Şəxsi profil məlumatlarına baxış
                                    </li>
                                    <li>Həftəlik və ya cari gün üçün dərs cədvəllərinə baxış
                                    </li>
                                    <li>Aktiv semestr üzrə dərslərin siyahısı, onlar üzərindən davamiyyət və qiymətlərə baxış
                                    </li>
                                    <li>Sərbəst işlərə və onların dəyərləndirilmə statusuna baxış
                                    </li>
                                    <li>Fərdi tədris planlarına baxış
                                    </li>
                                    <li>Təhsil haqqı və kredit borcları üzrə ödənişlərə və qalıq borca baxış
                                    </li>
                                    <li>Universitet kitabxanası üzrə istifadəçi kartına baxış
                                    </li>
                                    <li>Kollokvium və imtahan cədvəllərinə baxış
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={MTPortal12} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal13} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal14} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal15} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal16} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal17} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal18} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal19} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal20} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={MTPortal21} alt="Müəllim və tələbə portalı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default MTPortalPage