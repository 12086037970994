import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Ders1 from "../style/icons/Ders/ders1.svg";
import Ders2 from "../style/icons/Ders/ders2.svg";
import Ders3 from "../style/icons/Ders/ders3.svg";
import Ders4 from "../style/icons/Ders/ders4.svg";
import Ders5 from "../style/icons/Ders/ders5.svg";
import Ders6 from "../style/icons/Ders/ders6.svg";
import Ders7 from "../style/icons/Ders/ders7.svg";
import Ders8 from "../style/icons/Ders/ders8.svg";
import Ders9 from "../style/icons/Ders/ders9.svg";
import Ders10 from "../style/icons/Ders/ders10.svg";
import Ders11 from "../style/icons/Ders/ders11.svg";
import Ders12 from "../style/icons/Ders/ders12.svg";
import Ders13 from "../style/icons/Ders/ders13.svg";
import Ders14 from "../style/icons/Ders/ders14.svg";
import Ders15 from "../style/icons/Ders/ders15.svg";
import Ders16 from "../style/icons/Ders/ders16.svg";
import Ders17 from "../style/icons/Ders/ders17.svg";
import Ders18 from "../style/icons/Ders/ders18.svg";
import Ders19 from "../style/icons/Ders/ders19.svg";
import Ders20 from "../style/icons/Ders/ders20.svg";

const DersPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Dərslərin idarə
                                olunması</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tədris planları
                                </h2>
                                <p className='tim-contHeaderPage'>Tədris planı hər bir universitetin tədris prosesinin, tədris olunan ixtisasların konstitisiyası da adlandırılır.  </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Fənnlərin qruplaşdırılması üçün Fənn Bölümlərinin yaradılması
                                    </li>
                                    <li>Həmin müddətdə tədris olunacaq fənnlərin hər biri üzrə göstəricilərin (tədris olunacaq semestr, kredit, mühazirə saatı, seminar saatı, laboratoriya saatı, həftəlik dərs yükü) qeydiyyatı
                                    </li>
                                    <li>Prerekvizit və korrekvizi fənnlərin təyinatı
                                    </li>
                                    <li>Fərqli illər üzrə eyni ixtisasa aid tədris planlarının yaradıla bilməsi və qəbul prosesində həmin ixtisasa uyğun tədris planının avtomatik təyin oluna bilməsi
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Ders1} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders2} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders3} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders4} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders5} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Ders6} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders7} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders8} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders9} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders10} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Fənn üzrə mövzular
                                </h2>
                                <p className='tim-contHeaderPage'>Tədris olunan fənnlər üzrə təqvim tematik planların hazırlanması, həm də kollokvium və imtahanlar zamanı avtomatik bilet generasiyası zamanı ehtiyac duyulan mövzular toplusunun idarə edilməsi üçündür.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Fənn üzrə təqvim tematik planlar üçün mövzuların yaradılması.
                                    </li>
                                    <li>Fənn üzrə tələbələrə paylanılacaq sərbəst işlər üçün mövzuların yaradılması.
                                    </li>
                                    <li>Fənn üzrə keçirələcək kollokviumlar üçün sualların yaradılması.
                                    </li>
                                    <li>Fənn üzrə tələbələrə paylanılacaq laboratoriya işləri üçün mövzuların yaradılması.
                                    </li>
                                    <li>Fənn üzrə keçirələcək imtahanlar üçün sualların yaradılması.
                                    </li>
                                    <li>Hər bir mövzu və sualın konkret hansı davamedicilik üzrə aparıla bilməsi, hansı dərs tipinə(mühazirə və/və ya seminar) aid olması və hansı ixtisaslarda tədris oluna biləcəyini təyin etməyə imkan verən unikal bağlantı mexanizmi
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Dərs tapşırıqları
                                </h2>
                                <p className='tim-contHeaderPage'>Hər bir yeni tədris ili üzrə kafedraların dərs yükünü planlaşdırması üçün fakültələr tərəfindən göndərilən məlumatların idarə olunması moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Vahid ixtisas(kontingent sadəcə eyni ixtisas qruplarından ibarət ola bilər) üzrə dərs tapşırıqlarının hazırlanması
                                    </li>
                                    <li>Bir neçə ixtisas(kontingent fərqli ixtisas qruplarından ibarət ola bilər) üzrə dərs tapşırıqlarının hazırlanması
                                    </li>
                                    <li>İcmal mühazirə üzrə dərs tapşırıqlarının hazırlanması
                                    </li>
                                    <li>İstehsalat təcrübəsi üzrə dərs tapşırıqlarının hazırlanması
                                    </li>
                                    <li>Pedaqoji təcrübə üzrə dərs tapşırıqlarının hazırlanması
                                    </li>
                                    <li>Hər  bir tapşırıq forması üzrə fərqli kontingent tipinin (mühazirə, seminar, laboratoriya) hazırlanması
                                    </li>
                                    <li>Kafedra tərəfindən kontingent tərtibatı düzgün verilməmiş və geriyə göndərilmiş tapşırıqların analizi
                                    </li>
                                    <li>İmtahan qabağı saatların avtomatik hesablanması
                                    </li>
                                    <li>Vahid pəncərə üzrə tam baxış sənədi və excel-ə ötürülə bilməsi
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Ders11} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders12} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders13} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Ders14} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders15} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Dərs bölgüsü
                                </h2>
                                <p className='tim-contHeaderPage'>Hər bir yeni tədris ili üzrə fakültələrdən kafedralara yönləndirilmiş müxtəlif dərs tapşırıqlarına əsasən dərs yükünü planlaşdırmasının idarə olunması moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Hər bir kafedranın yalnız özünə aid dərs tapşırıqları görə bilməsi
                                    </li>
                                    <li>Konkret tapşırıq üzrə hansı müəllimlər təyin oluna bilməsinin avtomatik olaraq müəyyən edilməsi
                                    </li>
                                    <li>İşlənilməmiş dərs tapşırıqlarının avtomatik müəyyən olunması
                                    </li>
                                    <li>Kontingent tərtibatı düzgün verilməmiş tapşırıqların geriyə fakültəyə göndərilməsi
                                    </li>
                                    <li>Kafedranın öz müəllimlərinın universitet üzrə yükünün analiz olunması
                                    </li>
                                    <li>Universitet üzrə dərs yükünün müxtəlif parametrlərə əsasən analitikası
                                    </li>
                                    <li>Vahid pəncərə üzrə tam baxış sənədi və excel-ə ötürülə bilməsi
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Dərs cədvəli
                                </h2>
                                <p className='tim-contHeaderPage'>Hər bir yeni tədris ili üzrə universitet miqyasında dərs cədvəlinin formalaşdırılmasının idarə olunması moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Fərqli tədris periodları üzrə cədvəllərin yaradılması
                                    </li>
                                    <li>Əvvəlcədən müəllim heyətinin istək günlərinin qeydiyyatı
                                    </li>
                                    <li>İki fərqli yanaşma üzrə cədvəl qurula bilməsi: Müəllim və ya qrup
                                    </li>
                                    <li>Toqquşmaların avtomatik idarə olunması və qarşısının alıpnması: Müəllim üzrə, Tələbə üzrə, Otaq üzrə
                                    </li>
                                    <li>Seçilmiş dərsin hansı gün və saata yerləşdirilə bilməsinin avtomatik müəyyən olunması
                                    </li>
                                    <li>Alt və üst dərslərin nəzərə alınaraq eyni gün və saat üzrə eyni müəllim, tələbə və otağın yerləşdirilə bilməsi
                                    </li>
                                    <li>Dərsləri yerləşdirildikcə hansı dərslərin tam playnamadığının avtomatik bildirişi
                                    </li>
                                    <li>Kağız istifadəsinin tamamilə aradan qaldırılması
                                    </li>
                                    <li>Bütün fakültə və kafedralar arası avtomatik məlumat mübadiləsi
                                    </li>
                                    <li>Geniş analitika: müəllim, tələbə, otaq, qrup, ixtisas üzrə ən müxtəlif filterləmə imkanları
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Ders16} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders17} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders18} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders19} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Ders20} alt="Tədris planları" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DersPage