import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Kitabxana1 from '../style/icons/Kitabxana/Kitabxana1.svg';
import Kitabxana2 from '../style/icons/Kitabxana/Kitabxana2.svg';
import Kitabxana3 from '../style/icons/Kitabxana/Kitabxana3.svg';
import Kitabxana4 from '../style/icons/Kitabxana/Kitabxana4.svg';
import Kitabxana5 from '../style/icons/Kitabxana/Kitabxana5.svg';
import Kitabxana6 from '../style/icons/Kitabxana/Kitabxana6.svg';
import Kitabxana7 from '../style/icons/Kitabxana/Kitabxana7.svg';
import Kitabxana8 from '../style/icons/Kitabxana/Kitabxana8.svg';
import Kitabxana9 from '../style/icons/Kitabxana/Kitabxana9.svg';
import Kitabxana10 from '../style/icons/Kitabxana/Kitabxana10.svg';
import Kitabxana11 from '../style/icons/Kitabxana/Kitabxana11.svg';
import Kitabxana12 from '../style/icons/Kitabxana/Kitabxana12.svg';
import Kitabxana13 from '../style/icons/Kitabxana/Kitabxana13.svg';
import Kitabxana14 from '../style/icons/Kitabxana/Kitabxana14.svg';
import Kitabxana15 from '../style/icons/Kitabxana/Kitabxana15.svg';
import Kitabxana16 from '../style/icons/Kitabxana/Kitabxana16.svg';
import Kitabxana17 from '../style/icons/Kitabxana/Kitabxana17.svg';
import Kitabxana18 from '../style/icons/Kitabxana/Kitabxana18.svg';
import Kitabxana19 from '../style/icons/Kitabxana/Kitabxana19.svg';
import Kitabxana20 from '../style/icons/Kitabxana/Kitabxana20.svg';
import Kitabxana21 from '../style/icons/Kitabxana/Kitabxana21.svg';
import Kitabxana22 from '../style/icons/Kitabxana/Kitabxana22.svg';
import Kitabxana23 from '../style/icons/Kitabxana/Kitabxana23.svg';
import Kitabxana24 from '../style/icons/Kitabxana/Kitabxana24.svg';

const KitabxanaPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Elektron kitabxana</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='kitabxana'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Kitabxana strukturunun qurulması
                                </h2>
                                <p className='tim-contHeaderPage'>Kitabxana platformasının alt komponentlərinin strukturlaşdırılması moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Universitetdə mövcud olan kitabxanaların qeydiyyatı
                                    </li>
                                    <li>Hər bir kitabxana üzrə bölmə və şöbə alt komponentlərinin qeydiyyatı
                                    </li>
                                    <li>Müəllif reyestrinin hazırlanması
                                    </li>
                                    <li>Redaktor reyestrinin hazırlanması
                                    </li>
                                    <li>Nəşriyyat orqanları reyestrinin hazırlanması
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Kitabxana1} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana2} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana3} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana4} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana5} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana6} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana7} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana8} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana9} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana10} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='kitabxana'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Kitabxana11} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana12} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana13} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana14} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana15} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana16} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana17} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana18} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                               
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Elektron kataloqun formalaşdırılması
                                </h2>
                                <p className='tim-contHeaderPage'>Kitabxana fondunun qeydiyyatı moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Hər bir kitabxana üzrə mövcud fondun qeydiyyatı

                                    </li>
                                    <li>Fənlərin və onların mövzu reyestrinin formalaşdırılması
                                    </li>
                                    <li>Yeni daxil olmaların qeydiyyatı və avtomatik inventarlaşdırılması
                                    </li>
                                    <li>Vahid inventar pəncərəsi
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='kitabxana'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                Elektron kitab dövriyyəsi
                                </h2>
                                <p className='tim-contHeaderPage'>Kitabxana üzvlərinə fondun vəsatlərinin paylanılması və monitorinqi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Vahid tələbə və işçi informasiya sisteminə inteqrasiya
                                    </li>
                                    <li>Eyni üzvün bir və ya bir neçə kitabxana üzrə fərqli istifadəçi kartlarının formalaşdırılması
                                    </li>
                                    <li>Sorğulara əsasən ən müxtəlif kriteriyalara görə axtarış alt sistemi və materialın inventar durumunun avtomatik yenilənməsi
                                    </li>
                                    <li>Vahid pəncərə alt sistemlərinə və portallara inteqrasiya
                                    </li>                                  
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                            <Carousel.Item>
                                    <Image src={Kitabxana19} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana20} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana21} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana22} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana23} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Kitabxana24} alt="Kitabxana sisteminin elektronlaşması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default KitabxanaPage