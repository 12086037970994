import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import AkademikKontingent1 from "../style/icons/AkademikKontingent/ak1.svg";
import AkademikKontingent2 from "../style/icons/AkademikKontingent/ak2.svg";
import AkademikKontingent3 from "../style/icons/AkademikKontingent/ak3.svg";
import AkademikKontingent4 from "../style/icons/AkademikKontingent/ak4.svg";
import AkademikKontingent5 from "../style/icons/AkademikKontingent/ak5.svg";
import AkademikKontingent6 from "../style/icons/AkademikKontingent/ak6.svg";
import AkademikKontingent7 from "../style/icons/AkademikKontingent/ak7.svg";
import AkademikKontingent8 from "../style/icons/AkademikKontingent/ak8.svg";
import AkademikKontingent9 from "../style/icons/AkademikKontingent/ak9.svg";
import AkademikKontingent10 from "../style/icons/AkademikKontingent/ak10.svg";
import AkademikKontingent11 from "../style/icons/AkademikKontingent/ak11.svg";
import AkademikKontingent12 from "../style/icons/AkademikKontingent/ak12.svg";
import AkademikKontingent13 from "../style/icons/AkademikKontingent/ak13.svg";
import AkademikKontingent14 from "../style/icons/AkademikKontingent/ak14.svg";
import AkademikKontingent15 from "../style/icons/AkademikKontingent/ak15.svg";
import AkademikKontingent16 from "../style/icons/AkademikKontingent/ak16.svg";
import AkademikKontingent17 from "../style/icons/AkademikKontingent/ak17.svg";
import AkademikKontingent18 from "../style/icons/AkademikKontingent/ak18.svg";
import AkademikKontingent19 from "../style/icons/AkademikKontingent/ak19.svg";
import AkademikKontingent20 from "../style/icons/AkademikKontingent/ak20.svg";
import AkademikKontingent21 from "../style/icons/AkademikKontingent/ak21.svg";
import AkademikKontingent22 from "../style/icons/AkademikKontingent/ak22.svg";
import AkademikKontingent23 from "../style/icons/AkademikKontingent/ak23.svg";
import AkademikKontingent24 from "../style/icons/AkademikKontingent/ak24.svg";
import AkademikKontingent25 from "../style/icons/AkademikKontingent/ak25.svg";
import AkademikKontingent26 from "../style/icons/AkademikKontingent/ak26.svg";
import AkademikKontingent27 from "../style/icons/AkademikKontingent/ak27.svg";
import AkademikKontingent28 from "../style/icons/AkademikKontingent/ak28.svg";
import AkademikKontingent29 from "../style/icons/AkademikKontingent/ak29.svg";
import AkademikKontingent30 from "../style/icons/AkademikKontingent/ak30.svg";
import AkademikKontingent31 from "../style/icons/AkademikKontingent/ak31.svg";


const AkademikKontingentPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Akademik kontingent</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='akademikKontingent'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tələbələr
                                </h2>
                                <p className='tim-contHeaderPage'>Qəbul prosesinin ardınca tələbələrin formalaşdırılması moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Qəbul məlumatlarının avtomatik tələbə məlumatlarına transformasiyası
                                    </li>
                                    <li>Tələbə köçürmələrinin qeydiyyatı və avtomatik tələbə məlumatlarına transformasiyası
                                    </li>
                                    <li>Tələbə biletlərinin formalaşdırılması
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent1} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent2} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent3} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent4} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent5} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>                            

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='akademikKontingent'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent6} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent7} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent8} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent9} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent10} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent11} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent12} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent13} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent14} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tələbə vahid pəncərəsi
                                </h2>
                                <p className='tim-contHeaderPage'>İstənilən tələbə haqqında vahid pəncərə üzərindən müfəssəl məlumatlara baxış moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Tələbə qəbul olduğu ixtisas haqqında məlumatlar
                                    </li>
                                    <li>Tələbənin şəxsi profili haqqında məlumatlar
                                    </li>
                                    <li>Tələbənin həftəlik cədvəli
                                    </li>
                                    <li>Tələbənin cari ana qədər aldığı qiymətlər
                                    </li>
                                    <li>Tələbənin aktiv akademik borcları
                                    </li>
                                    <li>Tələbənin cari ana qədər aldığı qiymətlər
                                    </li>
                                    <li>Tələbənin təhsil haqqı və kredit borcları üzrə ödənişləri
                                    </li>
                                    <li>Tələbənin fərdi tədris planı
                                    </li>
                                    <li>Tələbənin kitabxana üzrə məlumatları
                                    </li>
                                    <li>Sənəd dövriyyəsi üzrə tələbəyə aid iş axınları (əmr, təqdimat, ərizə)
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='akademikKontingent'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                Qruplar
                                </h2>
                                <p className='tim-contHeaderPage'>Tələbələrin müxtəlif ixtisas qrupları üzrə formalaşdırılması və idarə edilməsi moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Tədris ilinin əvvəlindən verilmiş proqnozlara uyğun yeni qrupların yaradılması
                                    </li>
                                    <li>Tələbə sayına əsasən müxtəlif dərslərdə qrupların yarım qruplara transformasiyası
                                    </li>
                                    <li>Əlavə tədris quplarının yaradılması
                                    </li>
                                    <li>Akademik borcların ödənilməsi üçün alt qrupların formalaşdırılması
                                    </li>
                                    <li>Alt qrupların formalaşdırılması zamanı ekvivalent dərs imkanı
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent15} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent16} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent17} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent18} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent19} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>                            
                                <Carousel.Item>
                                    <Image src={AkademikKontingent20} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent21} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>  
                                <Carousel.Item>
                                    <Image src={AkademikKontingent22} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent23} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>  
                                <Carousel.Item>
                                    <Image src={AkademikKontingent24} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent25} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>                               
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='akademikKontingent'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent26} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent27} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent28} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent29} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent30} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={AkademikKontingent31} alt="Akademik kontingent" className='tim-EtrafliImg' />
                                </Carousel.Item>                              
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                Qrup vahid pəncərəsi
                                </h2>
                                <p className='tim-contHeaderPage'>İstənilən qrup haqqında vahid pəncərə üzərindən müfəssəl məlumatlara baxış moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Qrupun ixtisas haqqında məlumatlar
                                    </li>
                                    <li>Qrupdakı ana tələbələr haqqında məlumatlar
                                    </li>
                                    <li>Qrupdakı alt tələbələr haqqında məlumatlar
                                    </li>
                                    <li>Qrupun həftəlik cədvəli
                                    </li>
                                    <li>Qrupun hansı dərslər üzrə yarım qruplara bölünməsi və onlar haqqında məlumatlar
                                    </li>
                                    <li>Qrupun tədris planı – Fənn bölümü və ya semestrlər üzrə
                                    </li>
                                    <li>Qrupdakı tələbələrin cari ana qədər aldığı qiymətlər
                                    </li>                                
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AkademikKontingentPage