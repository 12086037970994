import React from 'react';
import CarouselSlider from "react-carousel-slider";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowRestore,
  faColumns,
  faCalculator,
  faFileAlt,
  faBook,
  faUniversity,
  faPencilRuler,
  faChartPie,
  faFolderOpen,
  faAddressCard,
  faShieldAlt,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

let data = [
  {
    des: "Universitet strukturu",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper blue",
    iconColor: "icon blue",
    iconImg: faUniversity,
  },
  {
    des: "Akademik kontingent",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper purple",
    iconColor: "icon purple",
    iconImg: faFolderOpen,
  },
  {
    des: "Dərslərin idarə olunması",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper yellow",
    iconColor: "icon yellow",
    iconImg: faColumns,
  },
  {
    des: "Elektron jurnal",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper red",
    iconColor: "icon red",
    iconImg: faWindowRestore,
  },
  {
    des: "Mühasibatlığın idarəsi",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper green",
    iconColor: "icon green",
    iconImg: faCalculator,
  },
  {
    des: "Sənəd dövriyyəsi",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper greenlight",
    iconColor: "icon greenlight",
    iconImg: faFileAlt,
  },
  {
    des: "Elektron kitabxana",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper orange",
    iconColor: "icon orange",
    iconImg: faBook,
  },
  {
    des: "İmtahan prosesi",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper greenHello",
    iconColor: "icon greenHello",
    iconImg: faPencilRuler,
  },

  {
    des: "Elektron hesabat",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper orangeLight",
    iconColor: "icon orangeLight",
    iconImg: faChartPie,
  },
  {
    des: "Müəllim və tələbə portalı",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper purpleLight",
    iconColor: "icon purpleLight",
    iconImg: faAddressCard,
  },
  {
    des: "Təhlükəsizliyin təmin edilməsi",
    imgSrc: "https://i.imgur.com/7nbAJ0C.jpg",
    borderColor: "swiper pinkHello",
    iconColor: "icon pinkHello",
    iconImg: faShieldAlt,
  },
];

const Carusels = () => {
  let sliderBoxStyle = {
    height: "256px",
    backgroundColor: "unset",
  };

  let itemsStyle = {
    background: "none",
  };

  let buttonSetting = {
    placeOn: "middle-outside",
  };

  let manner = {
    autoSliding: { interval: "3s" },
    duration: "1s",
    // circular: true
  };

  let rBtnCpnt = (
    <div className="slider-right">
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
  let lBtnCpnt = (
    <div className="slider-left">
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  let dotsSetting = {
    placeOn: "bottom",
    style: {
      dotSize: "5px",
      currDotColor: "#FFFFFF",
      margin: "0px auto 0px",
      Dotcolor: "#707070"
    },
  };

  let customSlideCpnts = data.map((item, index) => (
    <Nav.Link href={"/#" + index} key={index} className="p-0">
      <div className={item.borderColor}>
        <img src={item.imgSrc} className="d-none" alt='digitalEdu'></img>
        <div className="iconDiv">
          <FontAwesomeIcon icon={item.iconImg} className={item.iconColor} />
        </div>
        <div className="sliderText">{item.des}</div>
      </div>
    </Nav.Link>
  ));

  return (
    <div className="">
      <div style={{ margin: " auto", position: "relative" }}>
        <CarouselSlider
          slideCpnts={customSlideCpnts}
          manner={manner}
          itemsStyle={itemsStyle}
          buttonSetting={buttonSetting}
          sliderBoxStyle={sliderBoxStyle}
          rBtnCpnt={rBtnCpnt}
          lBtnCpnt={lBtnCpnt}
          dotsSetting={dotsSetting}
        />
      </div>
    </div>
  )
}

export default Carusels