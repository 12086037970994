import React from 'react';
import { Container, Row, Col, Form, Button, Nav } from "react-bootstrap";
import Carusels from '../Carusel/Carusels';

const Slider = () => {
  return (
    <div className="carusel" id="">
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="tim-title">Tədrisin idarə edilməsini  texnologiya fırtınasına həvalə et!</h1>
            <p className="tim-headercontext" title="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley">
            DigitalEdu innovativ həllər platforması ilə tədris prosesinin aparılmasını kağız üzərindən tam rəqəmsala daşıya bilərsiniz.
            </p>
            <Form className="d-flex justify-content-center align-items-start">
              <Button variant="outline-success" className="tim-DemoBtn">
                <Nav.Link href="#muracietEt"> Demo üçün müraciət et</Nav.Link>
              </Button>
            </Form>
            <Carusels/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Slider