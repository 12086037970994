import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbars from './components/Navbar/Navbars';
import "./components/style/css/navbar.css";
import "./components/style/css/form.css";
import "./components/style/css/footer.css";
import Forms from './components/Form/Forms';
import Footers from './components/Footer/Footers';
import HomePage from './components/Pages/HomePage';
import JurnalPage from './components/Pages/JurnalPage';
import DersPage from './components/Pages/DersPage';
import MuhasibatliqPage from './components/Pages/MuhasibatliqPage';
import TehlukesizlikPage from './components/Pages/TehlukesizlikPage';
import QurumPage from './components/Pages/QurumPage';
import MTPortalPage from './components/Pages/MTPortalPage';
import UniversitetStruktoruPage from './components/Pages/UniversitetStruktoruPage';
import KitabxanaPage from './components/Pages/KitabxanaPage';
import AkademikKontingentPage from './components/Pages/AkademikKontingentPage';
import ImtahanProsesiPage from './components/Pages/ImtahanProsesiPage';
import HesabatlarPage from './components/Pages/HesabatlarPage';
import AboutPage from './components/Pages/AboutPage';

const App = () => {
  return (
    <>
      <Navbars />
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/ElektronJurnal" exact element={<JurnalPage />} />
          <Route path="/DerslerinIdareEdilmesi" exact element={<DersPage/>} />
          <Route path="/MuhasibatliginIdareEdilmesi" exact element={ <MuhasibatliqPage/> } />
          <Route path="/TehlukesizliyinTeminEdilmesi" exact element={ <TehlukesizlikPage/> } />
          <Route path="/QurumlarArasiSenedDovriyyesi" exact element={ <QurumPage/> } />
          <Route path="/MuellimTelebePortali" exact element={ <MTPortalPage/> } />
          <Route path="/UniversitetStruktorununQurulmasi" exact element={ <UniversitetStruktoruPage/> } />
          <Route path="/ElektronKitabxana" exact element={ <KitabxanaPage/> } />
          <Route path="/AkademikKontingent" exact element={ <AkademikKontingentPage/> } />
          <Route path="/ImtahanProsesininOnlineTeshkilEdilmesi" exact element={ <ImtahanProsesiPage/> } />
          <Route path="/HesabatlarinElektronTertibi" exact element={ <HesabatlarPage/> } />
          <Route path="/Haqqimizda" exact element={ <AboutPage/> } />
        </Routes>
      </BrowserRouter>
      <Forms />
      <Footers />
    </>
  )
}

export default App