import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Struktor1 from '../style/icons/universitetSturukturu/Struktur1.svg';
import Struktor2 from '../style/icons/universitetSturukturu/Struktur2.svg';
import Struktor3 from '../style/icons/universitetSturukturu/Struktur3.svg';
import Struktor4 from '../style/icons/universitetSturukturu/Struktur4.svg';
import Struktor5 from '../style/icons/universitetSturukturu/Struktur5.svg';
import Struktor6 from '../style/icons/universitetSturukturu/Struktur6.svg';
import Struktor7 from '../style/icons/universitetSturukturu/Struktur7.svg';
import Struktor8 from '../style/icons/universitetSturukturu/Struktur8.svg';
import Struktor9 from '../style/icons/universitetSturukturu/Struktur9.svg';
import Struktor10 from '../style/icons/universitetSturukturu/Struktur10.svg';
import Struktor11 from '../style/icons/universitetSturukturu/Struktur11.svg';
import Struktor12 from '../style/icons/universitetSturukturu/Struktur12.svg';
import Struktor13 from '../style/icons/universitetSturukturu/Struktur13.svg';
import Struktor14 from '../style/icons/universitetSturukturu/Struktur14.svg';
import Struktor15 from '../style/icons/universitetSturukturu/Struktur15.svg';
import Struktor16 from '../style/icons/universitetSturukturu/Struktur16.svg';
import Struktor17 from '../style/icons/universitetSturukturu/Struktur17.svg';
import Struktor18 from '../style/icons/universitetSturukturu/Struktur18.svg';

const UniversitetStruktoruPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Universitet strukturunun qurulması</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Universitet üzrə strukturun formalaşdırılması
                                </h2>
                                <p className='tim-contHeaderPage'>Universitetdə mövcud olan qurumlar, işçi kontingenti üzrə qeydiyyat moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Qurumların(Rektorluq, fakültə, kafedra, şöbə, mərkəz) və tabelilik ierarxiyasının qurulması
                                    </li>
                                    <li>Otaqların və onların  təyinatının qeydiyyatı
                                    </li>
                                    <li>Vəzifələr və elmi dərəcələr reyestrinin qurulması
                                    </li>
                                    <li>İşçi kontingentinin qeydiyyatı
                                    </li>
                                    <li>Tədris ili üzrə qeyri iş günlərinin qeydiyyatı və tədris cədvəlinə avtomatik inteqrasiya
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Struktor1} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor2} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor3} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor4} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor5} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor6} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor7} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor8} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor9} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor10} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Struktor11} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor12} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor13} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor14} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor15} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor16} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor17} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Struktor18} alt="Universitet üzrə strukturun formalaşdırılması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tədris üzrə strukturun formalaşdırılması
                                </h2>
                                <p className='tim-contHeaderPage'>Universitetdə mövcud olan ixtisaslar, fənlərin qeydiyyatı moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>İxtisasların, ixtisaslaşmaların və proqramların qeydiyyatı
                                    </li>
                                    <li>Fənlərin və onların mövzu reyestrinin formalaşdırılması
                                    </li>
                                    <li>Kafedraların tədris etdiyi fənlərin reyestrinin qeydiyyatı
                                    </li>
                                    <li>Hər bir fənn üzrə müxtəif göstəricələrə(saatlar, yük) malik dərslərin qeydiyyatı
                                    </li>
                                    <li>Tədris periodlarının qeydiyyatı
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default UniversitetStruktoruPage