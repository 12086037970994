import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Jurnal1 from "../style/icons/Jurnal/jurnal1.svg";
import Jurnal2 from "../style/icons/Jurnal/jurnal2.svg";
import Jurnal3 from "../style/icons/Jurnal/jurnal3.svg";
import Jurnal4 from "../style/icons/Jurnal/jurnal4.svg";
import Jurnal5 from "../style/icons/Jurnal/jurnal5.svg";
import Jurnal6 from "../style/icons/Jurnal/jurnal6.svg";
import Jurnal7 from "../style/icons/Jurnal/jurnal7.svg";
import Jurnal8 from "../style/icons/Jurnal/jurnal8.svg";
import Jurnal9 from "../style/icons/Jurnal/jurnal9.svg";
import Jurnal10 from "../style/icons/Jurnal/jurnal10.svg";
import Jurnal11 from "../style/icons/Jurnal/jurnal11.svg";
import Jurnal12 from "../style/icons/Jurnal/jurnal12.svg";
import Jurnal13 from "../style/icons/Jurnal/jurnal13.svg";
import Jurnal14 from "../style/icons/Jurnal/jurnal14.svg";



const JurnalPage = () => {
    return (
        <>
          <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Elektron jurnal</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Jurnalların tərtibatı
                                </h2>
                                <p className='tim-contHeaderPage'>Tədris prosesinin özəyini təşkil edən proseslərin (qiymətləndirmə, davamiyyət, sərbəst işlər) vahid mərkəzdən idarə edilməsi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Cari tədris ili üçün keçiriləcək dərs və dərs tiplərinin(mühazirə, seminar, laboratoriya) avtomatik müəyyən olunması
                                    </li>
                                    <li>Hər bir qrup üzrə cari tədris ilindəki tədris planına əsasən dərslərin avtomatik yerləşdirilməsi
                                    </li>
                                    <li>Cədvəl ilə inteqrasiya nəticəsində dərs tarixlərinin(dərs sayının) avtomatik müəyyən olunması
                                    </li>
                                    <li>Eyni ixtisas, qrup  və dərs əsasında cari tədris ili semestr üzrə yalnız bir jurnalın yaradıla bilməsinn kontrolu
                                    </li>
                                    <li>Müəllim portalına avtomatik inteqrasiya
                                    </li>
                                    <li>Tələbə portalına avtomatik inteqrasiya
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Jurnal1} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal2} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal3} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal4} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                    <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Jurnal5} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal6} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal7} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>                           
                            </Carousel>                
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Təqvim tematik planların hazırlanması
                                </h2>
                                <p className='tim-contHeaderPage'>Müxtəlif ixtisaslar üzrə tədris olunan fənnlər üzrə təqvim tematik planların hazırlanmasının idarə edilməsi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Cari tədris ili üçün keçiriləcək dərs və dərs tiplərinin(mühazirə, seminar, laboratoriya) avtomatik müəyyən olunması
                                    </li>
                                    <li>Cədvəl ilə inteqrasiya nəticəsində dərs tarixlərinin(dərs sayının) avtomatik müəyyən olunması
                                    </li>
                                    <li>Təqvim tematik planın bağlı olduğu ixtisas və dərsə əsasən, yalnız onlara uyğun mövzu paketinin avtomatik təyin olunması
                                    </li>
                                    <li>Təqvim tematik planın bağlı olduğu ixtisas, kurs və dərsə əsasən bu planın hansı qruplara aid olmasının avtomatik təyinatı
                                    </li>
                                    <li>Eyni ixtisas, kurs və dərs əsasında cari tədris ili semestr üzrə yalnız bir dəfə yaradılmasının kontrolu
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Davamiyyət və qiymətləndirmə
                                </h2>
                                <p className='tim-contHeaderPage'>Vahid mərkəzdən bütün tələbələrin dərsə davamiyyəti və dərs ilə bağlı bütün qiymətləndirmənin idarə oluna bilinməsi moduludur  </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Cari tədris ili üçün keçiriləcək dərs və dərs tiplərinin(mühazirə, seminar, laboratoriya) avtomatik müəyyən olunması
                                    </li>
                                    <li>Hər bir qrup üzrə cari tədris ilindəki tədris planına əsasən dərslərin avtomatik yerləşdirilməsi
                                    </li>
                                    <li>Cədvəl ilə inteqrasiya nəticəsində dərs tarixlərinin(dərs sayının) avtomatik müəyyən olunması
                                    </li>
                                    <li>Eyni ixtisas, qrup  və dərs əsasında cari tədris ili semestr üzrə yalnız bir jurnalın yaradıla bilməsinn kontrolu
                                    </li>
                                    <li>Müəllim portalına avtomatik inteqrasiya
                                    </li>
                                    <li>Tələbə portalına avtomatik inteqrasiya
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Jurnal8} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal9} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal10} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal11} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                    <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Jurnal12} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Jurnal13} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>
                               
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Sərbəst işlər
                                </h2>
                                <p className='tim-contHeaderPage'>Jurnal üzrə hər bir tələbəyə sərbəst işlərin paylanılması və qiymətləndirilməsi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Hər tələbəyə əvvəlcədən müəyyən olunmuş sayda sərbəst işlərin paylanılması
                                    </li>
                                    <li>Tələbəyə təyin olunmuş sərbəst işlərin əvvəlcədən müəyyən olunmuş qiymət aralığı üzrə dəyərləndirilməsi
                                    </li>
                                    <li>Jurnalın bağlı olduğu ixtisas və dərsə əsasən, yalnız onlara uyğun sərbəst iş mövzu paketinin avtomatik təyin olunması
                                    </li>
                                    <li>Tələbələr üzrə sərbəst işlərin statusunun (təyin edilib, təhvil verilib) montorinqi
                                    </li>
                                    <li>Sərbəst işlərin dəyərləndirilməsi zamanı avtomatik olaraq yekun qiymətin hesablanması
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Laboratoriya işləri
                                </h2>
                                <p className='tim-contHeaderPage'>Jurnal üzrə hər bir tələbəyə laboratoriya işlərinin paylanılması və qiymətləndirilməsi moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Hər tələbəyə əvvəlcədən müəyyən olunmuş sayda(dərs saatlarına əsasən) laboratoriya işlərinin paylanılması
                                    </li>
                                    <li>Tələbəyə təyin olunmuş laboratoriya işlərinin təhvil alınması
                                    </li>
                                    <li>Jurnalın bağlı olduğu ixtisas və dərsə əsasən, yalnız onlara uyğun laboratoriya iş mövzu paketinin avtomatik təyin olunması
                                    </li>
                                    <li>Tələbələr üzrə laboratoriya işlərinin statusunun (təyin edilib, təhvil verilib) montorinqi
                                    </li>
                                    <li>Laboratoriya işlərinin qəbulu zamanı avtomatik olaraq yekun qiymətin hesablanması
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Jurnal14} alt=" Jurnalların tərtibatı" className='tim-EtrafliImg' />
                                </Carousel.Item>                             
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default JurnalPage