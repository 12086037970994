import React from 'react';
import {
    Navbar,
    Nav,
    Container,
    Form,
    Button,
    Offcanvas,
} from "react-bootstrap";
import logo from "../style/img/logo.png";

const Navbars = () => {
    return (
        <>
            <Navbar expand="xxl" className="tim-navbar sticky-top">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} className='tim-logo' alt='timGroup DigitalEdu' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-xxl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
                                <Navbar.Brand href="/">
                                    <img src={logo} className='tim-logo' alt='timGroup DigitalEdu' />
                                </Navbar.Brand>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center align-items-xxl-center align-items-md-start flex-grow-1 tim-nav">
                            <Nav.Link href="/Haqqimizda" className='tim-link'>Haqqımızda</Nav.Link>
                                <Nav.Link href="/#niyeBiz" className='tim-link timpage'>Niyə biz?</Nav.Link>
                                <Nav.Link href="/#modullar" className='tim-link timpage'>Modullar</Nav.Link>                              
                                <Nav.Link href="#elaqe" className='tim-link timpage'>Əlaqə</Nav.Link>
                            </Nav>
                            <Form className="d-flex timpage">
                                <Button variant="outline-success timpage" className="tim-apply">
                                    <Nav.Link href="#muracietEt"> Müraciət et</Nav.Link>
                                </Button>
                            </Form>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default Navbars