import React from 'react';
import {
    Container, Row, Col,Image
} from "react-bootstrap";
import "../style/css/pages.css";
import About1 from "../style/icons/about1.svg";
import About2 from "../style/icons/about2.svg";

const AboutPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Haqqımızda</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='About'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <p className='tim-contHeaderPage'>Tədris prosesi özündə ən müxtəlif komponentləri ehtiva edən kompleks bir mexanizmdir.</p>
                                <br />
                                <p className='tim-contHeaderPage'>
                                    Tələbənin universitetə qəbul olmasından məzun olana qədər keçdiyi bir dövrdə, universitetin bütün qurumları arasında mürəkkəb informasiya axını baş verir.
                                </p>
                                <br />
                                <p className='tim-contHeaderPage'>Tələbənin qəbulu, tədrisə hazırlıq, müəllim heyətinin dərs yükünün hazırlanması, dərs cədvəllərinin hazırlanması, kredit borcu olan tələbələrin paralel təhsili, davamiyyət, qiymətlərdirmə, imtahanlar, mühasibatlıq, sənəd dövriyyəsi, müəllim və tələbə portalları – bütün bunların aparılması gərgin insan əməyi və ciddi kontrol mexanizmləri. </p>
                            </div>
                        </Col>

                        <Col lg={6} sm={12}>
                        <Image src={About1} alt='Tədris prosesi özündə ən müxtəlif komponentləri ehtiva edən kompleks bir mexanizmdir' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='About'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                        <Image src={About2} alt='Tədris prosesi özündə ən müxtəlif komponentləri ehtiva edən kompleks bir mexanizmdir' className='img-fluid' />
                        </Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <p className='tim-contHeaderPage'>Bu qədər mürəkkəb mexanimzlərin kağız üzərində aparıldığını da düşünsək, sərf olunacaq zaman və resurların miqyasını hiss etmək çətin deyildir.
                                    Komandamız bu mexanizmləri maksimum rəqəmsallaşdırmaq və tədris ocaqlarının gündəlik işlərini saatlardan dəqiqələrə endirmək üçün yola çıxdı.
                                </p>
                                <br />
                                <p className='tim-contHeaderPage'>
                                    Platformamız ən son innovativ texnologiyalar əsasında hazırlanıb. Bura maksimum sürət, minimum insan əməyi, maksimum təhlükəsizlik daxildir.

                                </p>
                                <br />
                                <p className='tim-contHeaderPage'>Qürur hissi ilə deyə bilərik ki, yeganə komandayıq ki, həm tədris prosesləri üzrə, həm də mühəndislik ekspertizası bir yerdə fəaliyyət göstərir.
                                    Tam əminliklə deyə bilərik ki, tədrisin tam avtomatlaşdırılması yalnız bu platformanın tətbiqi ilə mümkündür.
                                </p>
                                <p className='tim-contHeaderPage'>
                                    DigitalEdu platofmasında qeydiyyatdan keçin və texnologiya fırtınasının bir parçası olun!

                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AboutPage