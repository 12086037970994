import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Qurum1 from "../style/icons/Qurum/s1.svg";
import Qurum2 from "../style/icons/Qurum/s2.svg";
import Qurum3 from "../style/icons/Qurum/s3.svg";
import Qurum4 from "../style/icons/Qurum/s4.svg";
import Qurum5 from "../style/icons/Qurum/s5.svg";
import Qurum6 from "../style/icons/Qurum/s6.svg";
import Qurum7 from "../style/icons/Qurum/s7.svg";
import Qurum8 from "../style/icons/Qurum/s8.svg";
import Qurum9 from "../style/icons/Qurum/s9.svg";
import Qurum10 from "../style/icons/Qurum/s10.svg";
import Qurum11 from "../style/icons/Qurum/s11.svg";
import Qurum12 from "../style/icons/Qurum/s12.svg";
import Qurum13 from "../style/icons/Qurum/s13.svg";
import Qurum14 from "../style/icons/Qurum/s14.svg";
import Qurum15 from "../style/icons/Qurum/s15.svg";
import Qurum16 from "../style/icons/Qurum/s16.svg";
import Qurum17 from "../style/icons/Qurum/s17.svg";
import Qurum18 from "../style/icons/Qurum/s18.svg";
import Qurum19 from "../style/icons/Qurum/s19.svg";
import Qurum20 from "../style/icons/Qurum/s20.svg";

const QurumPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Qurumlar arası
                                sənəd dövriyyəsi</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Vahid İş Pəncərəsi
                                </h2>
                                <p className='tim-contHeaderPage'>Sənəd dövriyyəsi sisteminin vəzifəsindən asılı olmayaraq, bütün iş axışının aparıldığı vahid pəncərədir. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Bir pəncərə üzərindən müxtəlif sənəd tiplərinə uyğun formalar üzərindən iş axışına başlanıla bilinməsi: Sorğular,  Ərizə,  Təqdimat,  Əmr, Məktublar
                                    </li>
                                    <li>Sənəd İcraçı Reyestrinin qurulması və hər bir iş axınına müxtəlif icraatçıların təyin olunması
                                    </li>
                                    <li>İş axının izlənilməsi, hansı icraatının son əməliyyatının və vaxtı ötmüş icraatların avtomatik təyinatı
                                    </li>
                                    <li>Çox pilləli ierarxiya prinsipinə əsasən icraatın alt icraatlara ötürülməsi və izlənilməsi
                                    </li>
                                    <li>Bütün iş axını (arxiv və aktiv) üzrə müfəssəl axtarış sistemi
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Qurum1} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum2} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum3} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum4} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum5} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Qurum6} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum7} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum8} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum9} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum10} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum11} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum12} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum13} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum14} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum15} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum16} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum17} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Sorğular
                                </h2>
                                <p className='tim-contHeaderPage'>Qurumlar arası sənəd dövriyyəsinin başlanğıc nöqtəsi olaraq Ümumi Şöbəyə göndərilən sorğu moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Fakültələrin müxtəlif sənəd formalarını və onların kontentinin avtomatik formalaşdırılaraq Ümumi Şöbəyə göndərilməsi
                                    </li>
                                    <li>Ərizə və Təqdimat tipli sənədlərin alt tipləri üçün vizual kontent formalaşdırma aləti
                                    </li>
                                    <li> Sorğunun Ümumi Şöbə tərəfindən qəbul edilməsinin və icraat vəziyyətinə nəzarət mexanizmi
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Sənədlərin icraata götürülməsi
                                </h2>
                                <p className='tim-contHeaderPage'>Ümumi şöbəyə daxil olmuş sorğular əsasında iş axışına start verilməsi moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Fakültələrdən daxil olmuş ərizə və ya təqdimat tipli sorğular əsasında rektor dərkənarı əsasında icraatçıların qeyd edilərək iş axınına başladılması
                                    </li>
                                    <li>Kənar qurumlardan daxil olmuş məktubların rektor dərkənarı əsasında icraatçıların qeyd edilərək iş axınına başladılması
                                    </li>
                                    <li>Rektor əmrlərinin icraatçıların qeyd edilərək iş axınına başladılması
                                    </li>
                                    <li>İcraatçıların şəxsi kabinetinə avtomatik onların icra etməli olduğu bildirişlərin avtomatik daxil olması
                                    </li>
                                    <li>İcraatçılar öz iş axınını alt icraat ötürə bilməsi və yalnız onların bitirməsi halında öz iş axınını bitirə bilməsinin kontrolu
                                    </li>
                                    <li>İcraatı bitmiş iş axınlarının Vahid Pəncərə üzərindən analizi və yeni dərkənar üzrə təkrar başladılması
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Qurum18} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum19} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Qurum20} alt="Vahid İş Pəncərəsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default QurumPage