import React from 'react';
import {
    Container, Row, Col, Image, Nav
} from "react-bootstrap";
import FooterLogo from "../style/img/logo.png";
import Facebook from '../style/icons/facebook.svg';
import Linkedin from '../style/icons/linkedin.svg';
// import Instagram from '../style/icons/instagram.svg';
import Youtube from '../style/icons/youtube.svg';
import Location from '../style/icons/location.svg';
import Phone from '../style/icons/phone.svg';
import Communication from '../style/icons/communication.svg';


const Footers = () => {

    return (<Container>
        <div className='tim-BgColorFooter' id='elaqe'>

            <Row>
                <Col className="" lg={3} sm={6}>
                    <Image src={FooterLogo} alt='DigitalEdu' className='img-fluid mb-3' />
                </Col>
                <Col className="text-left mt-1 mb-2" lg={3} sm={6}>
                    <div className='d-flex bet-iconText'>
                        <div>
                            <Image src={Location} alt="digitaledu facabook" />
                        </div>
                        <div className='tim-com'>
                            Xocalı 37, <br />
                            Dəmirçi Plaza,  <br />
                            23-cü mərtəbə
                        </div>
                    </div>
                    <br />
                    <div className='d-flex bet-iconText'>
                        <div className=''>
                            <Image src={Phone} alt="digitaledu facabook" />
                        </div>
                        <div className='tim-com'>
                            +994 12 310 35 20
                        </div>
                    </div>
                    <br />
                    <div className='d-flex bet-iconText'>
                        <div>
                            <Image src={Communication} alt="digitaledu facabook" />
                        </div>
                        <div className='tim-com'>
                            info@digitaledu.az
                        </div>
                    </div>
                </Col>
                <Col className="text-left mb-2" lg={3} sm={6}>
                    <Nav className="d-flex flex-column">
                        <Nav.Link href="/Haqqimizda" className='tim-link'>Haqqımızda</Nav.Link>
                        <Nav.Link href="/#niyeBiz" className='tim-link'>Niyə biz?</Nav.Link>
                        <Nav.Link href="/#modullar" className='tim-link'>Modullar</Nav.Link>
                        <Nav.Link href="/#faq" className='tim-link'>Tez-tez verilən suallar</Nav.Link>
                    </Nav>
                </Col>
                <Col className="text-left" lg={3} sm={6}>
                    <div className='tim-follow'>Bizi sosial şəbəkələrdə izlə</div>
                    <Nav className="d-flex flex-row">
                        <Nav.Link href="https://www.facebook.com/digitaleduaz" title='Facebook' className='tim-link'>
                            <Image src={Facebook} alt="digitaledu facabook" />
                        </Nav.Link>
                        {/* <Nav.Link href="#instagram" title='Instagram' className='tim-link'>
                            <Image src={Instagram} alt="digitaledu instagram" />
                        </Nav.Link> */}
                        <Nav.Link href="https://www.linkedin.com/company/digitaleduaz" title='Linkedin' className='tim-link'>
                            <Image src={Linkedin} alt="digitaledu linkedin" />
                        </Nav.Link>
                        <Nav.Link href="https://www.youtube.com/watch?v=ryNz81h328s&list=PL-z7kH9C8s5FlgvnbNSpUtFgwjDoXsZK-&index=21" title='Youtube' className='tim-link'>
                            <Image src={Youtube} alt="digitaledu youtube" />
                        </Nav.Link>
                    </Nav>
                </Col>

            </Row>

        </div>
        <Col className="" lg={12} xs={12}>
            <div className='tim-footerAlt'>© 2022 DigitalEDU. Bütün hüquqlar qorunur.</div>
        </Col>
    </Container>
    )
}

export default Footers