import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Muhasibatliq1 from "../style/icons/Muhasibatliq/muh1.svg";
import Muhasibatliq2 from "../style/icons/Muhasibatliq/muh2.svg";
import Muhasibatliq3 from "../style/icons/Muhasibatliq/muh3.svg";
import Muhasibatliq4 from "../style/icons/Muhasibatliq/muh4.svg";
import Muhasibatliq5 from "../style/icons/Muhasibatliq/muh5.svg";
import Muhasibatliq6 from "../style/icons/Muhasibatliq/muh6.svg";
import Muhasibatliq7 from "../style/icons/Muhasibatliq/muh7.svg";
import Muhasibatliq8 from "../style/icons/Muhasibatliq/muh8.svg";
import Muhasibatliq9 from "../style/icons/Muhasibatliq/muh9.svg";
import Muhasibatliq10 from "../style/icons/Muhasibatliq/muh10.svg";

const MuhasibatliqPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Mühasibatlığın
                                idarə olunması</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    İxtisas təhsil haqları
                                </h2>
                                <p className='tim-contHeaderPage'>Hər bir ixtisas üzrə illik təhsil haqlarının qeydiyyatının idarə edilməsi moduludur.</p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>İxtisas üzrə illik təhsil haqlarının qeydiyyatının aparılması
                                    </li>
                                    <li>İxtisas haqqının qeydiyyatının yalnız yeni qiymətlər formalaşdıqda qeydiyyatına ehtiyac duyulur. Başqa sözlə, qəbul zamanı ən son qeydiyyat hansıdırsa, platforma həmin qiymət üzərindən davam edir
                                    </li>
                                    <li>Müxtəlif parametrlər üzrə təhsil haqlarının axtarışı və analiz imkanları
                                    </li>

                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq1} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq2} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq3} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq4} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq5} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq6} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq7} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Ödənişlərin qəbulu
                                </h2>
                                <p className='tim-contHeaderPage'>Tələbələrin ödənişlərinin qəbulunun, borcların hesablanması və hesabatların formalaşdırılması moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Bankın universitetə təqdim etdiyi kütləvi ödəniş reyestri fayllarının avtomatik analiz olunması, hansı ödənişin hansı tələbəyə və ödənişin tipinin (təhsil haqqı və ya kredit borcu) avtomatik təyinatı

                                    </li>
                                    <li>Ödəniş təyinatında bəzən səhvlər olsa da, xüsusi alqoritm tələbənin təyin oluna bilinməməsi ehtimalını minimuma endirir
                                    </li>
                                    <li>Tələbə təyin oluna bilmədikdə, mühasibin heç bir dekanlığa müraciət etmədən tədris bazasına inteqrasiya olunmuş axtarış paneli vasitəsi ilə tələbəni müəyyən edə bilməsi
                                    </li>
                                    <li>Ödəniş reyestrinin universitetə aid olmadığı halda imtina statusunun verilməsi və həmin ödənişin arxivdə saxlanılması
                                    </li>
                                    <li>Ödənişin təsdiqi zamanı tələbənin balansının avtomatik formalaşdırılması
                                    </li>
                                    <li>Tələbə ödənişi geri qaytarmaq istədikdə  və ya ödənişin təyinatını(təhsil haqqı və ya kredit borcu) dəyişmək istədikdə, balansın avtomatik yenidən formalaşması
                                    </li>
                                    <li>Paralel olaraq ödənişlərin bir-bir işlənilə bilinməsi imkanı
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Ödənişlərin analizi
                                </h2>
                                <p className='tim-contHeaderPage'>Vahid tələbə məlumat bazası üzərindən bütün ödənişlərin  analizi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Müxtəlif parametrlər üzrə tələbələrin və onlara aid ödənişlərin axtarış imkanları
                                    </li>
                                    <li>Hər bir tələbə üzrə ödəniş tarixçəsinin tədris illəri və tarixlər üzrə baxıla bilinməsi
                                    </li>
                                    <li>Hər bir tələbənin ən son təhsil haqqı və kredit  borcuna bacıla bilinməsi
                                    </li>
                                    <li>Universitet üzrə ödənişlərlə bağlı müxtəlif hesabat formaları
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq8} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq9} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Muhasibatliq10} alt="Mühasibatlığın idarə olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default MuhasibatliqPage;