import React from 'react';
import Accordions from '../Accordion/Accordions';
import ModulBody from '../Moduls/ModulBody';
import ModulHeader from '../Moduls/ModulHeader';
import Slider from '../Slider/Slider';
import About from '../WhyWe/About';
import '../style/css/carusel.css';
import '../style/css/whyWe.css';
import "../style/css/moduls.css";
import "../style/css/accordion.css";

const HomePage = () => {
    return (
        <>
           <Slider/>
           <About/>
           <ModulHeader/>
           <ModulBody/>
           <Accordions/>
        </>
    )
}

export default HomePage