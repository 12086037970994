import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Hesabat1 from "../style/icons/Hesabatlar/Hesabatlar1.svg";
import Hesabat2 from "../style/icons/Hesabatlar/Hesabatlar2.svg";
import Hesabat3 from "../style/icons/Hesabatlar/Hesabatlar3.svg";
import Hesabat4 from "../style/icons/Hesabatlar/Hesabatlar4.svg";
import Hesabat5 from "../style/icons/Hesabatlar/Hesabatlar5.svg";
import Hesabat6 from "../style/icons/Hesabatlar/Hesabatlar6.svg";
import Hesabat7 from "../style/icons/Hesabatlar/Hesabatlar7.svg";
import Hesabat8 from "../style/icons/Hesabatlar/Hesabatlar8.svg";
import Hesabat9 from "../style/icons/Hesabatlar/Hesabatlar9.svg";
import Hesabat10 from "../style/icons/Hesabatlar/Hesabatlar10.svg";
import Hesabat11 from "../style/icons/Hesabatlar/Hesabatlar11.svg";
import Hesabat12 from "../style/icons/Hesabatlar/Hesabatlar12.svg";
import Hesabat13 from "../style/icons/Hesabatlar/Hesabatlar13.svg";
import Hesabat14 from "../style/icons/Hesabatlar/Hesabatlar14.svg";
import Hesabat15 from "../style/icons/Hesabatlar/Hesabatlar15.svg";
import Hesabat16 from "../style/icons/Hesabatlar/Hesabatlar16.svg";



const HesabatlarPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Hesabatların elektron tərtibatı</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Akademik Transkript
                                </h2>
                                <p className='tim-contHeaderPage'>Hər bir tələbənin cari tədris ili semestrə qədər, tədris olunan fənlər üzrə topladığı yekun qiymətlər toplusudur. </p>

                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat1} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat2} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                               
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat3} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat4} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                               
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Müəllimin işçi tədris planı
                                </h2>
                                <p className='tim-contHeaderPage'>Tədris ilinin hər bir semestri üzrə müəllimin tədris edəcəyi fənlərin və onların müxtəlif göstəricilərinin yer aldığı hesabatdır.</p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tələbənin tədris planı
                                </h2>
                                <p className='tim-contHeaderPage'>Tədris ilinin hər bir semestri üzrə tələbənin alacağı fənlərin və onların müxtəlif göstəricilərinin yer aldığı hesabatdır.</p>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat5} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat6} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat7} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat8} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat9} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat10} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    İxtisas üzrə işçi tədris planı
                                </h2>
                                <p className='tim-contHeaderPage'>Hər bir ixtisasın tədris ilinin semestrləri üzrə tədris olunacaq fənlər və onların göstəriciləri haqqında hesabatdır. </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Şifrə vərəqi
                                </h2>
                                <p className='tim-contHeaderPage'>İmtahan prosesinə hazırlıq zamanı hansı tələbənin imtahana buraxılacağı, hansı səbəbdən buraxılmayacağı və imtahan qabağı qiymətlərin əks olunduğu hesabatdır. </p>

                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat11} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat12} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>                               
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat13} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat14} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>                        
                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    İmtahan vərəqi
                                </h2>
                                <p className='tim-contHeaderPage'>İmtahan prosesi zamanı, tələbənin hansı qiymətlərlə imtahana girş etdiyi və imtahan zamanı topladığı bal və yekun qiymətin əks olunduğu hesabatdır.</p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='Hesabatların elektron
                                tərtib olunması'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Tədris qrafiki
                                </h2>
                                <p className='tim-contHeaderPage'>Universitetin akademik təqvimi də adlanır. Hər bir tədris ili və semestr üzrə hansı tədris hissələrinin hansı tarixlərdə olacağını əks etdirir.</p>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Hesabat15} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Hesabat16} alt="Hesabatların elektron
                                tərtib olunması" className='tim-EtrafliImg' />
                                </Carousel.Item>                    
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default HesabatlarPage