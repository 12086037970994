import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Form, Button,
} from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

const Forms = () => {

    const [values, setValues] = useState({
        fullName: '',
        company_name: '',
        email: '',
        company_number: '',
    });

    const selfe = () => {
        Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: ' Uğurla göndərildi!',
            showConfirmButton: false,
            timer: 1500
        })
    }

    // const [successMessage, setSuccessMessage] = useState(false);
    const [status, setStatus] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_00budc4', 'template_vfrx24q', values, 'R7XWzQ3VvwK2lPWH5')
            .then(response => {
                // console.log('SUCCESS!', response);
                setValues({
                    fullName: '',
                    company_name: '',
                    email: '',
                    company_number: ''
                });
                setStatus('SUCCESS');
                // setSuccessMessage(true);
                selfe();
            }, error => {
                console.log('FAILED...', error);
            });
    }

    useEffect(() => {
        if (status === 'SUCCESS') {
            setTimeout(() => {
                setStatus('');
                // setSuccessMessage(true);
            }, 3000);
        }
    }, [status]);

    const handleChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div className='tim-ModulBgWhiteColor' id='muracietEt'>
            <Container>
                <Row>
                    <Col className="text-center" lg={12}>
                        <h3 className="tim-subtitleUnder">Demo versiya üçün <br />
                            qeydiyyat</h3>

                    </Col>
                </Row>

                <Form className='tim-form' onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={10}>
                            <Row>
                                <Col lg={3} sm={12} className='mb-1 mt-1 form-col'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Tam ad"
                                        className='tim-label'
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Tam ad"
                                            className="tim-input"
                                            name='fullName'
                                            value={values.fullName}
                                            onChange={handleChange} required />
                                    </FloatingLabel>
                                </Col>
                                <Col lg={3} sm={12} className='mb-1 mt-1 form-col'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Şirkət adı"
                                        className='tim-label'
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Şirkət adı"
                                            className="tim-input"
                                            name='company_name'
                                            value={values.company_name}
                                            onChange={handleChange} required />
                                    </FloatingLabel>
                                </Col>
                                <Col lg={3} sm={12} className='mb-1 mt-1 form-col'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="E-mail"
                                        className='tim-label'
                                    >
                                        <Form.Control
                                            type="email"
                                            placeholder="E-mail"
                                            className="tim-input"
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange} required />
                                    </FloatingLabel>
                                </Col>
                                <Col lg={3} sm={12} className='mb-1 mt-1 form-col'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Mobil nömrə"
                                        className='tim-label'
                                    >
                                        <Form.Control type="number" placeholder="Mobil nömrə" className="tim-input" 
                                            name='company_number'
                                            value={values.company_number}
                                            onChange={handleChange} required />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} className='mb-1 mt-1 form-col'>
                            <Button className='tim-FormBtn' type='submit'>Müraciət et</Button>
                        </Col>
                    </Row>
                </Form>
                {/* {successMessage ? (<Alert variant="success" className='mt-3' >
                    {successMessage}  Uğurla göndərildi!
                </Alert>) : ''} */}
            </Container>
        </div>
    )
}

export default Forms