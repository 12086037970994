import React from 'react';
import {
    Container, Row, Col, Accordion
} from "react-bootstrap";

const Accordions = () => {
    return (
        <div className='tim-ModulBgWhiteColor' id='faq'>
            <Container>
                <Row>
                    <Col className="text-center" lg={12}>
                        <h4 className="tim-subtitleUnder" title='Tez-tez verilən suallar'>Tez-tez verilən suallar</h4>

                        <Accordion defaultActiveKey="0" className='tim-accordion'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>DigitalEDU nədir?</Accordion.Header>
                                <Accordion.Body>
                                    DigitalEdu platformasının yaradılmasında əsas hədəf, universitetlərin gündəlik biznes proseslərinin aparılmasını kağız əməyindən tam avtomatlaşdırılmış  rəqəmsal mühitə daşımaqdır.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Platformaya kimlər qoşula bilər?</Accordion.Header>
                                <Accordion.Body>
                                    Azərbaycanda fəaliyyət göstərən bütün ali və orta ixtisas müəssisələri platformada qeydiyyatdan keçə bilər.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>DigitalEDU platformasının xidmətindən istifadə üçün nə etmək lazımdır?</Accordion.Header>
                                <Accordion.Body>
                                    Platform bulud texnologiyaları üzərində qurulub. Bu, platformanı istifadəyə başlamaq üçün heç bir yatırım etməyə ehtiyac olmaması anlamına gəlir. Server, xüsusi infrastruktur və bunları idarə edən kadrlara ehtiyac yoxdur. Sadəcə qeydiyyatdan keçməklə innovativ həllərin toplandığı platformanın sahibisiniz!
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Demo versiya nədir?</Accordion.Header>
                                <Accordion.Body>
                                    Demo versiya, platformanın imkanları ilə əyani tanış olmaq və platformadan sınaq rejimində faydalanmaq üçündür. Ortaya çıxacaq suallarla bağlı komandamız sizə tam yardımçı olacaqdır.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Accordions