import React from 'react';
import {
    Container, Row, Col, Carousel, Image
} from "react-bootstrap";
import "../style/css/pages.css";
import Təhlükəsizlik1 from "../style/icons/Tehlukesizlik/Tehlukesizlik1.svg";
import Təhlükəsizlik2 from "../style/icons/Tehlukesizlik/Tehlukesizlik2.svg";
import Təhlükəsizlik3 from "../style/icons/Tehlukesizlik/Tehlukesizlik3.svg";
import Təhlükəsizlik4 from "../style/icons/Tehlukesizlik/Tehlukesizlik4.svg";
import Təhlükəsizlik5 from "../style/icons/Tehlukesizlik/Tehlukesizlik5.svg";
import Təhlükəsizlik6 from "../style/icons/Tehlukesizlik/Tehlukesizlik6.svg";
import Təhlükəsizlik7 from "../style/icons/Tehlukesizlik/Tehlukesizlik7.svg";
import Təhlükəsizlik8 from "../style/icons/Tehlukesizlik/Tehlukesizlik8.svg";

const TehlukesizlikPage = () => {
    return (
        <>
            <div className='tim-BgBlue'>
                <Container>
                    <Row>
                        <Col lg={12} sm={12}>
                            <h1 className='tim-headerOuther'>Təhlükəsizliyin
                                təmin olunması</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    İstifadəçilərin qeydiyyatı
                                </h2>
                                <p className='tim-contHeaderPage'>Platformaya giriş imtiyazları qazanmaq üçün istifadəçilərin qeydiyyatı moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>İşçi heyəti üçün istifadəçilərin yaradılması. İşçi həm də dərs apara bilirsə, platforma onlara əlavə olaraq müəllim portalına giriş imtiyazlarını avtomatik ayarlayır
                                    </li>
                                    <li>Tələbə heyəti üçün istifadəçilərin yaradılması – tələbələr sistemdən qeydiyatdan keçə bilir və platforma onların giriş imtiyazlarını avtomatik ayarlayır
                                    </li>
                                    <li>Unudulmuş parolların email ünvan vasitəsi ilə bərpası mexanizmi
                                    </li>
                                    <li>Administrator tərəfindən istifadəçinin parolunun dəyişdirilməsi
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik1} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik4} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik5} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik6} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik7} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik8} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik2} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>

                            </Carousel>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    İstifadəçilər üzrə giriş imtiyazlarının ayarlanması
                                </h2>
                                <p className='tim-contHeaderPage'>İstifadəçilərin müxtəlif modullar üzrə imtiyazlarının idarə edilməsi moduludur.  </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Bankın universitetə təqdim etdiyi kütləvi ödəniş reyestri fayllarının avtomatik analiz olunması, hansı ödənişin hansı tələbəyə və ödənişin tipinin (təhsil haqqı və ya kredit borcu) avtomatik təyinatı

                                    </li>
                                    <li>İstifadəçinin əsas menyuda görə biləcəyi linklərin ayarlanması
                                    </li>
                                    <li>İstifadəçinin daxil olduğu modulda edə biləcəyi əməliyyatlar toplusunun ayarlanması
                                    </li>
                                    <li>İstifadəçinin daxil olduğu modulda görə biləcəyi məlumatlar ayarlanması
                                    </li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='tim-outherPage' id='jurnal'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div className='tim-container'>
                                <h2 className='tim-subHeaderPage'>
                                    Vəzifələr üzrə giriş imtiyazlarının ayarlanması
                                </h2>
                                <p className='tim-contHeaderPage'>Vəzifə əsasında müxtəlif modullar üzrə imtiyazların idarə edilməsi moduludur. </p>
                                <div className='tim-subHeadLi'>
                                    Əsas özəlliklər:
                                </div>
                                <br />
                                <ul className='tim-subUl'>
                                    <li>Hansı vəzifə sahibinin əsas menyuda görə biləcəyi linklərin ayarlanması
                                    </li>
                                    <li>Hansı vəzifə sahibinin daxil olduğu modulda edə biləcəyi əməliyyatlar toplusunun ayarlanması
                                    </li>
                                    <li>Hansı vəzifə sahibinin daxil olduğu modulda görə biləcəyi məlumatlar ayarlanması
                                    </li>

                                </ul>
                            </div>
                        </Col>
                        <Col lg={1} sm={12}></Col>
                        <Col lg={5} sm={12}>
                            <Carousel>
                                <Carousel.Item>
                                    <Image src={Təhlükəsizlik3} alt="Təhlükəsizliyin təmin edilməsi" className='tim-EtrafliImg' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default TehlukesizlikPage