import React from 'react'
import { Button, Col, Container, Image, Row, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Ders from "../style/img/1.png";
import Jurnal from "../style/img/2.png";
import Muhasibatliq from "../style/img/3.png";
import Qurum from "../style/img/4.png";
import Kitabxana from "../style/img/5.png";
import Universitet from "../style/img/6.png";
import Portal from "../style/img/7.png";
import Imtahan from "../style/img/8.png";
import Hesabat from "../style/img/9.png";
import Ishci from "../style/img/10.png";
import Tehlukesizlik from "../style/img/11.png";

const ModulBody = () => {
    return (
        <>
            <div className='tim-ModulBgWhiteColor' id='0'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Universitet strukturunun qurulması</div>
                            <p className='tim-subUnderDetails'>Sistemin ilkin sazlaması olaraq, korpus/otaq fondunun qeydiyyatı, qurumların
                                (fakültə, kafedra və s.) və tabelilik mexanizminin ayarlanması, fənn, ixtisas kataloqunun hazırlanması</p>
                            <Button className='tim-modulBtn blue'>
                                <Nav.Link href="/UniversitetStruktorununQurulmasi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Universitet} alt='Universitet strukturunun qurulması' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgLightColor' id='1'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Ishci} alt='İşçi və tələbə informasiya sistemi' className='img-fluid' />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Akademik kontingent</div>
                            <p className='tim-subUnderDetails'>Universitetin bütün tələbə heyətinin, onların qruplaşdırılmasının və idarə edilməsi üçün nəzərdə tutulub.</p>
                            <Button className='tim-modulBtn purple'>
                                <Nav.Link href="/AkademikKontingent" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgWhiteColor' id='2'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Dərslərin idarə olunması</div>
                            <p className='tim-subUnderDetails'>Tədris planlarının qurulması, dərs tapşırıqları və dərs bölgüsünü aparılması, dərs saatlarının tam paylanmasına nəzarət, müəllim, tələbə kontingentinin və otaq toqquşmasının kontrolu və fakültələr arası sürətli informasiya paylaşımı</p>
                            <Button className='tim-modulBtn brown'>
                                <Nav.Link href="/DerslerinIdareEdilmesi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Ders} alt='Dərslərin idarə olunması' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgLightColor' id='3'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Jurnal} alt='Jurnalların tərtib edilməsi' className='img-fluid' />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Elektron jurnal</div>
                            <p className='tim-subUnderDetails'>Tələbə davamiyyəti, qiymətləndirməsi, sərbəst iş və laboratoriya işlərinin paylanılması və real vaxt zəminində imtahan qabağı qiymətin hesablanmasının tam elektron formada təşkili</p>
                            <Button className='tim-modulBtn pink'>
                                <Nav.Link href="/ElektronJurnal" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgWhiteColor' id='4'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Mühasibatlığın idarə olunması</div>
                            <p className='tim-subUnderDetails'>İxtisas təhsil haqlarının qeydiyyatı, bank ödənişlərinin (təhsil haqqı və kredit borcu)  sistemə inteqrasiyası, borcların hesablanması və hesabatların təqdim olunması</p>
                            <Button className='tim-modulBtn green'>
                                <Nav.Link href="/MuhasibatliginIdareEdilmesi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Muhasibatliq} alt='Mühasibatlığın idarə olunması' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgLightColor' id='5'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Qurum} alt='Qurumlar arası sənəd dövriyyəsi' className='img-fluid' />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Qurumlar arası sənəd dövriyyəsi</div>
                            <p className='tim-subUnderDetails'>Qurumların sayından və onlar arasındakı informasiya mübadiləsi həcmindən asılı olmadan, bütün kağız işlərinin elektron formada aparılması və mərkəzi nəzarət sistemi</p>
                            <Button className='tim-modulBtn greenlight'>
                                <Nav.Link href="/QurumlarArasiSenedDovriyyesi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgWhiteColor' id='6'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Elektron kitabxana</div>
                            <p className='tim-subUnderDetails'>Elektron kitabxana kataloqunun formalaşdırılması, mərkəzləşdirilmiş istifadəçi reyestri, kitab dövriyyəsinə nəzarət və inventarların idarə olunması</p>
                            <Button className='tim-modulBtn orange'>
                                <Nav.Link href="/ElektronKitabxana" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Kitabxana} alt='Kitabxana sisteminin elektronlaşması' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgLightColor' id='7'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Imtahan} alt='İmtahan prosesinin online təşkil edilməsi' className='img-fluid' />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>İmtahan prosesinin aparılması</div>
                            <p className='tim-subUnderDetails'>İmtahan qabağı qiymətlərin və buraxılmayacaq tələbələrin avtomatik formalaşdırılması, imtahan cədvəlinin qurulması, biletlərin generasiyası və tələbələr üzrə dəyərləndirmə</p>
                            <Button className='tim-modulBtn greenHello'>
                                <Nav.Link href="/ImtahanProsesininOnlineTeshkilEdilmesi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgWhiteColor' id='8'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Hesabatların elektron tərtibatı</div>
                            <p className='tim-subUnderDetails'>Tədrisin standard formalarının və müxtəlif analitik hesabatların anında formalaşdırıla bilməsi</p>
                            <Button className='tim-modulBtn orangeLight'>
                                <Nav.Link href="/HesabatlarinElektronTertibi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Hesabat} alt='Hesabatların elektron tərtib olunması' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgLightColor' id='9'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Portal} alt='Müəllim və tələbə portalı' className='img-fluid' />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Müəllim və tələbə portalı</div>
                            <p className='tim-subUnderDetails'>Müəllim və tələbələrin vahid portalda şəxsi məlumatlarına (cədvəl, dərslər, davamiyyət, qiymətlər, materiallar) baxa bilmək və onlar üzərində əməliyyatlar apara bilmək</p>
                            <Button className='tim-modulBtn purpleLight'>
                                <Nav.Link href="/MuellimTelebePortali" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className='tim-ModulBgWhiteColor' id='10'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='tim-subUnderTitle'>Təhlükəsizliyin təmin olunması</div>
                            <p className='tim-subUnderDetails'>Kiber hücumların intensivləşdiyi bir dövrdə tələbə və işçi heyətinin konfidensial məlumatlarının qorunması, tədris prosesini ehtiva edən məlumatların toxunulmazlığının təminatı olması yalnız ciddi təhlükəsizlik mexanizmləri sayəsində mümkündür.</p>
                            <Button className='tim-modulBtn pinkHello'>
                                <Nav.Link href="/TehlukesizliyinTeminEdilmesi" target="_blank" >
                                    <FontAwesomeIcon icon={faChevronRight} className='' />
                                </Nav.Link>
                            </Button>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Image src={Tehlukesizlik} alt='Təhlükəsizliyin təmin olunması' className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

    )
}

export default ModulBody